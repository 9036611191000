import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { useAuth0 } from './utils/Auth0Provider';
import PageLoadingSpinner from './components/PageLoadingSpinner';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import NavigationBar from './components/NavigationBar';

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ './components/Dashboard'));
const Impressum = lazy(() => import(/* webpackChunkName: "Impressum" */ './pages/Impressum'));
const DataProtection = lazy(() => import(/* webpackChunkName: "DataProtection" */ './pages/DataProtection'));
const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ './components/ErrorMessages/NotFound'));
const TabView = lazy(() => import(/* webpackChunkName: "TabView" */ './components/TabView'));

export default function App() {
  const { loading } = useAuth0();

  if (loading) {
    return <PageLoadingSpinner />;
  }

  return (
    <NavigationBar>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>

        <ProtectedRoute
          path={[
            '/stations/:id',
            '/contracts/evco/:id',
            '/contracts/:contractId/digitalprechecks/:digitalPrecheckUuid',
            '/contracts/:id',
            '/stationclusters/:id',
            '/stationtypes/:id',
            '/cdrs/:id',
            '/customers/:id',
            '/users/:id',
            '/stations',
            '/vehicles',
            '/contracts',
            '/stationclusters',
            '/hubject-contracts',
            '/customers',
            '/cdrs',
            '/stationtypes',
            '/profile',
            '/users',
          ]}
        >
          <TabView />
        </ProtectedRoute>

        <ProtectedRoute exact path="/">
          <Dashboard />
        </ProtectedRoute>

        <ProtectedRoute exact path="/history">
          <Dashboard />
        </ProtectedRoute>
        <ProtectedRoute exact path="/favourites">
          <Dashboard />
        </ProtectedRoute>
        <ProtectedRoute exact path="/statistics">
          <Dashboard />
        </ProtectedRoute>

        <Route exact path="/impressum">
          <Suspense fallback={<PageLoadingSpinner />}>
            <Impressum />
          </Suspense>
        </Route>
        <Route exact path="/datenschutz">
          <Suspense fallback={<PageLoadingSpinner />}>
            <DataProtection />
          </Suspense>
        </Route>

        <Route>
          <Suspense fallback={<PageLoadingSpinner />}>
            <NotFound />
          </Suspense>
        </Route>
      </Switch>
    </NavigationBar>
  );
}
