import { createContext, useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';

import authOptions from '../config/auth';

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  );
};

export const Auth0Context = createContext();
export const useAuth0 = () => useContext(Auth0Context);

export async function setupAuth0Client() {
  let auth0Client;
  try {
    auth0Client = await createAuth0Client(authOptions);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);

    // When createAuth0Client throws an error (e.g. refresh token expired),
    // no auth0 client is created and the user cannot use the system at all.
    // In this case, we have to force logout so that the user can log in again
    if (e.error === 'invalid_grant') {
      auth0Client = new Auth0Client(authOptions);
      auth0Client.logout();
    } else {
      throw e;
    }
  }
  return auth0Client;
}

export const Auth0Provider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const auth0FromHook = await setupAuth0Client();
        setAuth0(auth0FromHook);
        if (window.location.search.includes('code=') || window.location.search.includes('error=')) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        const auth0IsAuthenticated = await auth0FromHook.isAuthenticated();
        setIsAuthenticated(auth0IsAuthenticated);

        if (auth0IsAuthenticated) {
          const auth0User = await auth0FromHook.getUser();
          setUser(auth0User);
        }
      } catch (e) {
        setErrorMessage(e.error);
      }

      setLoading(false);
    };
    initAuth0();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getErrorMessage = useCallback(() => {
    return errorMessage;
  }, [errorMessage]);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout({ returnTo: authOptions.redirect_uri, ...p }),
        getErrorMessage,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

Auth0Provider.propTypes = {
  children: PropTypes.node.isRequired,
};
