import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import './index.css';
import './config/l18n';
import store from './store';
import { Auth0Provider } from './utils/Auth0Provider';
import { PermissionsProvider } from './utils/PermissionsProvider';
import { NotificationProvider } from './utils/NotificationProvider';
import GlobalTheme from './utils/GlobalTheme';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { WizardProvider } from './utils/WizardProvider';

ReactDOM.render(
  <GlobalTheme>
    <Auth0Provider>
      <NotificationProvider>
        <PermissionsProvider>
          <HelmetProvider>
            <Provider store={store}>
              <BrowserRouter>
                <WizardProvider>
                  <App />
                </WizardProvider>
              </BrowserRouter>
            </Provider>
          </HelmetProvider>
        </PermissionsProvider>
      </NotificationProvider>
    </Auth0Provider>
  </GlobalTheme>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
