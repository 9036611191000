import PropTypes from 'prop-types';

import { usePermissions } from './PermissionsProvider';

export default function UserHas({ children, fallback, permission }) {
  const { hasPermissions, loadingPermissions } = usePermissions();

  if (loadingPermissions) {
    return null;
  }

  if (hasPermissions(permission)) {
    return <>{children}</>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }

  return null;
}

UserHas.defaultProps = {
  children: null,
  fallback: null,
};

UserHas.propTypes = {
  children: PropTypes.node,
  permission: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  fallback: PropTypes.node,
};
