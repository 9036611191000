import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import HomeStationIcon from '../Icons/HomeStationIcon';
import ContractIcon from '../Icons/ContractIcon';
import ChargingIcon from '../Icons/ChargingIcon';
import CustomerIcon from '../Icons/CustomerIcon';

import { MenuTitle, NavLinkStyled, NavIcon, NavText } from './Menu.parts';

function SearchMenu({ handleClose }) {
  const { t } = useTranslation(['search', 'translation']);

  return (
    <>
      <MenuTitle variant="h1">{t('Was möchtest du suchen?')}</MenuTitle>
      <NavLinkStyled to="/stations" permission="stations:read" onClick={handleClose}>
        <NavIcon>
          <HomeStationIcon />
        </NavIcon>
        <NavText variant="h2"> {t('Stationen')}</NavText>
      </NavLinkStyled>
      <NavLinkStyled to="/contracts" permission="contracts:read" onClick={handleClose}>
        <NavIcon>
          <ContractIcon />
        </NavIcon>
        <NavText variant="h2"> {t('Vertrag')}</NavText>
      </NavLinkStyled>
      <NavLinkStyled to="/customers" permission="customers:read" onClick={handleClose}>
        <NavIcon>
          <CustomerIcon />
        </NavIcon>
        <NavText variant="h2"> {t('Kunde')}</NavText>
      </NavLinkStyled>
      <NavLinkStyled to="/cdrs" permission="cdrs:read" onClick={handleClose}>
        <NavIcon>
          <ChargingIcon />
        </NavIcon>
        <NavText variant="h2">{t('translation:Ladevorgang')}</NavText>
      </NavLinkStyled>
    </>
  );
}

SearchMenu.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default SearchMenu;
