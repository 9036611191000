import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import { ReactComponent as Icon } from '../../assets/icons/customerIcon.svg';
import { ReactComponent as ThinIcon } from '../../assets/icons/customerIconThin.svg';

export default function CustomerIcon({ thin }) {
  const { t } = useTranslation('search');

  if (thin) {
    return <ThinIcon className="MuiSvgIcon-root" alt="search-customer-icon" aria-label={t('Kunde')} />;
  }
  return <Icon className="MuiSvgIcon-root" alt="search-customer-icon" aria-label={t('Kunde')} />;
}

CustomerIcon.defaultProps = {
  thin: false,
};

CustomerIcon.propTypes = {
  thin: PropTypes.bool,
};
