import PropTypes from 'prop-types';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

import { useAuth0 } from '../../utils/Auth0Provider';

import { NavigationText, NavListIcon, NavlistItem } from './NavigationBar.parts';

export default function LogoutButton({ open, text, delay }) {
  const { logout } = useAuth0();
  return (
    <NavlistItem button onClick={logout}>
      <NavListIcon>
        <LogoutIcon />
      </NavListIcon>
      <NavigationText disableTypography primary={text} open={open} delay={delay} />
    </NavlistItem>
  );
}

LogoutButton.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
};
