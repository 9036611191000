import i18n from 'i18next';
import ICU from 'i18next-icu';
import { de as deICU } from 'i18next-icu/locale-data/de';
import { en as enICU } from 'i18next-icu/locale-data/en';
import { initReactI18next } from 'react-i18next';

import DE from './locales/de.json';
import EN from './locales/en.json';

const getLanguageSetting = () => {
  try {
    const languageSettings = localStorage.getItem('lng');
    if (languageSettings === null) {
      return undefined;
    }
    return languageSettings;
  } catch (err) {
    return undefined;
  }
};

i18n
  .use(
    new ICU({
      localeData: [deICU, enICU],
    })
  )
  .use(initReactI18next)
  .init({
    resources: {
      DE,
      EN,
    },
    lng: getLanguageSetting() || 'DE',
    fallbackLng: 'DE',
    debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
