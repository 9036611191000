/* eslint-disable react/jsx-props-no-spreading */
import { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth0 } from '../utils/Auth0Provider';

import PageLoadingSpinner from './PageLoadingSpinner';

function ProtectedRoute({ children, tabs, ...rest }) {
  const { isAuthenticated } = useAuth0();

  return (
    <Route {...rest}>
      {isAuthenticated ? (
        <>
          {tabs}
          <Suspense fallback={<PageLoadingSpinner />}>{children}</Suspense>
        </>
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
  );
}

ProtectedRoute.defaultProps = {
  tabs: null,
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]).isRequired,
  tabs: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
};

export default ProtectedRoute;
