import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

import { DialogContentStyled } from './Wizard.parts';

const DialogContentLoading = styled(DialogContentStyled)`
  width: 250px;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function LoadingAction() {
  return (
    <DialogContentLoading>
      <CircularProgress color="primary" />
    </DialogContentLoading>
  );
}

export default LoadingAction;
