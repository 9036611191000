import PropTypes from 'prop-types';

import NavLinkPermission from './NavLinkPermission';
import { NavigationText, NavListIcon, NavlistItem, useStyles } from './NavigationBar.parts';

export default function NavigationLink({ open, icon: NavIcon, to, text, delay, permission }) {
  const { navLink, activeNavLink } = useStyles();
  return (
    <NavlistItem button>
      <NavLinkPermission to={to} className={navLink} activeClassName={activeNavLink} permission={permission}>
        <NavListIcon>
          <NavIcon />
        </NavListIcon>
        <NavigationText open={open} disableTypography primary={text} delay={delay} />
      </NavLinkPermission>
    </NavlistItem>
  );
}

NavigationLink.defaultProps = {
  permission: null,
};

NavigationLink.propTypes = {
  open: PropTypes.bool.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
  permission: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
