import styled from 'styled-components';
import { DialogActions, DialogContent, DialogTitle, Stepper, IconButton } from '@material-ui/core';

const DialogContentStyled = styled(DialogContent)`
  min-width: 300px;
  margin: ${(props) => (props.margin === 'dense' ? props.theme.spacing(2, 4, 2) : props.theme.spacing(3, 4, 2.5))};
  ${(props) => props.theme.breakpoints.down('sm')} {
    min-width: 200px;
    margin: ${(props) => props.theme.spacing(0)};
  }
`;

const DialogActionsRight = styled(DialogActions)`
  justify-content: flex-end;
`;

const DialogTitleBackground = styled(DialogTitle)`
  background-color: ${(props) => props.theme.palette.brabs.main};
  position: relative;
`;

const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -12px;
  position: relative;
  z-index: 1;
`;

const StepperStyled = styled(Stepper)`
  flex: 1 1 auto;
  max-width: 300px;
  ${(props) => props.theme.breakpoints.up('sm')} {
    margin-left: ${(props) => props.theme.spacing(5)}px;
    margin-right: ${(props) => props.theme.spacing(5)}px;
  }
`;

const CloseDeleteButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.primary.light};
  position: absolute;
  top: ${(props) => props.theme.spacing(0.5)}px;
  right: ${(props) => props.theme.spacing(0.5)}px;

  svg {
    width: 14px;
    height: 14px;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.brabs.main};
  }
`;

export {
  DialogContentStyled,
  DialogActionsRight,
  DialogTitleBackground,
  StepperWrapper,
  StepperStyled,
  CloseDeleteButton,
};
