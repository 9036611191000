const constants = {
  PAGE_TITLE: 'eeMobility Portal',
  EEMOBILITY: 'EEMOBILITY',
  STATION: 'STATION',
  STATIONTYPE: 'STATIONTYPE',
  CONTRACT: 'CONTRACT',
  CDR: 'CDR',
  CUSTOMER: 'CUSTOMER',
  PROFILE: 'PROFILE',
  USERMANAGEMENT: 'USERMANAGEMENT',
  VEHICLES: 'VEHICLES',
  USERDETAILS: 'USERDETAILS',
  ROLE_OPERATOR_BASE: 'ROLE_OPERATOR_BASE',
  accessCategories: {
    BUSINESS: 'BUSINESS',
    HOME: 'HOME',
    PUBLIC: 'PUBLIC',
  },
  connectionStatus: {
    CONNECTED: 'CONNECTED',
    DISCONNECTED: 'DISCONNECTED',
    UNKNOWN: 'UNKNOWN',
  },
  OCPP_1_6: 'OCPP_1_6',
  ETREL_ONE_WAY: 'ETREL_ONE_WAY',
  AC: 'AC',
  DC: 'DC',
  LMS: 'LMS',
  EMS: 'EMS',
  chargingMode: {
    DEFAULT: 'DEFAULT',
    LMS_STATIC: 'LMS_STATIC',
    LMS_OPTIMISED: 'LMS_OPTIMISED',
    LMS_POWER_REQUESTED: 'LMS_POWER_REQUESTED',
    LMS_POWER: 'LMS_POWER',
    EMS_SMART: 'EMS_SMART',
    EMS_POWER_REQUESTED: 'EMS_POWER_REQUESTED',
    EMS_POWER: 'EMS_POWER',
  },
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  lifecycleState: {
    PENDING: 'PENDING',
    READY: 'READY',
    OPERATIVE: 'OPERATIVE',
    OUT_OF_ORDER: 'OUT_OF_ORDER',
    AUTOMATIC_OUT_OF_ORDER: 'AUTOMATIC_OUT_OF_ORDER',
    INACTIVE: 'INACTIVE',
  },
  spotStatus: {
    AVAILABLE: 'Available',
    OCCUPIED: 'Occupied',
    INACTIVE: 'Inactive',
    UNAVAILABLE: 'Unavailable',
    UNKOWN: 'Unkown',
  },
  spotTypes: {
    CCS: 'CCS',
    CHADEMO: 'CHAdeMO',
    SCHUKO: 'Schuko',
    TYPE_1: 'Type 1',
    TYPE_2: 'Type 2',
    TYPE_2_CABLE: 'Type 2 Cable',
    TYPE_3: 'Type 3',
    UNSPECIFIED: 'UNSPECIFIED',
  },
  chargingNetworks: {
    HUBJECT: 'HUBJECT',
    ECLEARINGNET: 'ECLEARINGNET',
    ECAROC: 'ECAROC',
    MER: 'MER',
    FLOTTELADEN: 'FLOTTELADEN',
    EEMOBILITY: 'EEMOBILITY',
    CHARGEIT: 'CHARGEIT',
    GIREVE: 'GIREVE',
  },
  additionalFilters: {
    EXTERNALLY_MANAGED: 'EXTERNALLY_MANAGED',
    INVALID_CDRS_ONLY: 'INVALID_CDRS_ONLY',
  },
  stationPanels: {
    ADDITIONALINFO: 'ADDITIONALINFO',
    CUSTOMERINFO: 'CUSTOMERINFO',
    CONTRACTINFO: 'CONTRACTINFO',
    GEOLOCATION: 'GEOLOCATION',
    STATIONDATA: 'STATIONDATA',
    STATIONCONTROL: 'STATIONCONTROL',
    TECHNICALDATA: 'TECHNICALDATA',
    STATIONGROUP: 'STATIONGROUP',
  },
  contractPanels: {
    DRIVER: 'DRIVER',
    CONTRACTDATA: 'CONTRACTDATA',
    RFID: 'RFID',
    STATIONGROUPS: 'STATIONGROUPS',
  },
  cdrPanels: {
    DETAILS: 'DETAILS',
    AUTHENTIFIZIERUNG: 'AUTHENTIFIZIERUNG',
    LADESTATION: 'LADESTATION',
  },
  customerPanels: {
    ADRESSEN: 'ADRESSEN',
    ANSPRECHPARTNER: 'ANSPRECHPARTNER',
    EINLADUNGSLINKS: 'EINLADUNGSLINKS',
    PRODUCTS: 'PRODUCTS',
  },
  simCardDevices: {
    STATION: 'STATION',
    ROUTER: 'ROUTER',
  },
  meterTypes: {
    RLM: 'RLM',
    ESY: 'ESY',
    EMH: 'EMH',
  },
  meterControlZones: {
    AMPRION: 'AMPRION',
    TENNET_TSO: 'TENNET_TSO',
    TRANSNET_BW: 'TRANSNET_BW',
    FIFTY_HERTZ_TRANSMISSION: 'FIFTY_HERTZ_TRANSMISSION',
    VERBUND_AUSTRIAN_POWER_GRID: 'VERBUND_AUSTRIAN_POWER_GRID',
    SWISSGRID: 'SWISSGRID',
  },
  countries: ['AT', 'BE', 'CH', 'DE', 'FR', 'NL'],
  googleMapsOptions: {
    key: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: 'places',
  },
  stationClusterPannels: {
    STATIONS: 'STATIONS',
    DETAILS: 'DETAILS',
    STATIONSCLUSTER: 'STATIONSCLUSTER',
  },
  assetTypes: {
    IMAGE: 'IMAGE',
    FIRMWARE: 'FIRMWARE',
  },
  language: {
    EN: 'EN',
    DE: 'DE',
  },
  icons: {
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    PASSWORD: 'PASSWORD',
    SEARCH: 'SEARCH',
    STATIONCLUSTER: 'STATIONCLUSTER',
    STATIONTYPES: 'STATIONTYPES',
    STATIONTYPEDETAIL: 'STATIONTYPEDETAIL',
    CONTRACTDETAIL: 'CONTRACTDETAIL',
  },
  carOwnership: {
    COMPANY: 'COMPANY',
    PRIVATE: 'PRIVATE',
  },
  contractStatuses: ['ACTIVE', 'INACTIVE', 'DRAFT', 'UNKNOWN_STATUS'],
  digitalPrecheckPanels: {
    PLACEOFINSTALLATION: 'PLACEOFINSTALLATION',
    JUNCTIONBOX: 'JUNCTIONBOX',
    METERBOX: 'METERBOX',
    SIGNALMEASUREMENT: 'SIGNALMEASUREMENT',
    CABLEROUTE: 'CABLEROUTE',
  },
  digitalPrecheckPhotoTypes: {
    METER_BOX: 'METER_BOX',
    METER_BOX_SURROUNDINGS: 'METER_BOX_SURROUNDINGS',
    JUNCTION_BOX: 'JUNCTION_BOX',
    WALLBOX: 'WALLBOX',
    WALLBOX_UNMARKED: 'WALLBOX_UNMARKED',
    WALLBOX_MARKED: 'WALLBOX_MARKED',
    FLOOR_PLAN: 'FLOOR_PLAN',
    FLOOR_PLAN_UNMARKED: 'FLOOR_PLAN_UNMARKED',
    FLOOR_PLAN_MARKED: 'FLOOR_PLAN_MARKED',
    SURROUNDINGS: 'SURROUNDINGS',
    SURROUNDINGS_UNMARKED: 'SURROUNDINGS_UNMARKED',
    SURROUNDINGS_MARKED: 'SURROUNDINGS_MARKED',
  },
  digitalPrecheckStatus: {
    INITIATED: 'INITIATED',
    SUBMITTED: 'SUBMITTED',
    APPROVED: 'APPROVED',
    MANUAL_PRECHECK: 'MANUAL_PRECHECK',
    EMAIL_PRECHECK: 'EMAIL_PRECHECK',
  },
};

export default constants;
