import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import SearchIcon from '@material-ui/icons/Search';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PersonIcon from '@material-ui/icons/Person';

import CarIcon from '../components/Icons/CarIcon';
import HomeStationIcon from '../components/Icons/HomeStationIcon';
import PublicStationIcon from '../components/Icons/PublicStationIcon';
import BusinessStationIcon from '../components/Icons/BusinessStationIcon';
import ContractIcon from '../components/Icons/ContractIcon';
import StationTypeIcon from '../components/Icons/StationTypeIcon';
import ResetPasswordIcon from '../components/Icons/ResetPassword';
import StationClusterIcon from '../components/Icons/StationClusterIcon';
import StationTypeListIcon from '../components/Icons/StationTypeListIcon';
import CdrIcon from '../components/Icons/ChargingIcon';
import CustomerIcon from '../components/Icons/CustomerIcon';
import ContractDetailIcon from '../components/Icons/ContractDetailIcon';
import constants from '../config/constants';

const iconSwitcher = {
  [constants.CONTRACT]: ContractIcon,
  [constants.STATIONTYPE]: StationTypeIcon,
  [constants.USERMANAGEMENT]: GroupOutlinedIcon,
  [constants.USERDETAILS]: PersonIcon,
  [constants.PROFILE]: AccountBoxIcon,
  [constants.CDR]: CdrIcon,
  [constants.VEHICLES]: CarIcon,
  [constants.CUSTOMER]: CustomerIcon,
  [constants.USERMANAGEMENT]: GroupOutlinedIcon,
  [constants.accessCategories.PUBLIC]: PublicStationIcon,
  [constants.accessCategories.HOME]: HomeStationIcon,
  [constants.accessCategories.BUSINESS]: BusinessStationIcon,
  [constants.icons.ERROR]: ErrorIcon,
  [constants.icons.SUCCESS]: SuccessIcon,
  [constants.icons.PASSWORD]: ResetPasswordIcon,
  [constants.icons.SEARCH]: SearchIcon,
  [constants.icons.STATIONCLUSTER]: StationClusterIcon,
  [constants.icons.STATIONTYPES]: StationTypeListIcon,
  [constants.icons.STATIONTYPEDETAIL]: StationTypeIcon,
  [constants.icons.CONTRACTDETAIL]: ContractDetailIcon,
};

export default iconSwitcher;
