import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth0 } from '../../utils/Auth0Provider';
import Footer from '../Footer/Footer';

import Campaign from './Campaigns/Campaign';
import LoginForm from './LoginForm';

const Wrapper = styled.div`
  height: 100vh;
  background-color: ${(props) => props.theme.palette.secondary.light};
  display: flex;
  flex: 1 1 0;
  flex-direction: row;
`;

const Content = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentRight = styled(Content)`
  display: none;
  ${(props) => props.theme.breakpoints.up('sm')} {
    display: flex;
  }
`;

const CenteredLogin = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

function Login() {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Wrapper>
      <Content>
        <CenteredLogin>
          <LoginForm />
        </CenteredLogin>
        <Footer />
      </Content>
      <ContentRight>
        <Campaign />
      </ContentRight>
    </Wrapper>
  );
}

export default Login;
