import styled from 'styled-components';

// @TODO: This meeds to be refined further
const images = ['campaign_background.jpg', 'campaign_side2.jpg', 'campaign_side4.jpg', 'campaign_side5.jpg'];

const CampaignImage = styled.div`
  width: 100%;
  height: 100%;
  background: url(${(props) => props.src}) no-repeat;
  background-position: center;
  background-size: cover;
`;

function Campaign() {
  const imagePath = `${process.env.PUBLIC_URL}/images/campaign/`;

  return <CampaignImage src={imagePath + images[Math.floor(Math.random() * images.length)]} />;
}

export default Campaign;
