import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, CircularProgress } from '@material-ui/core';

const CircularProgressButton = styled(CircularProgress)`
  position: absolute;
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

function LoadingButton(props) {
  const { children, isLoading, disabled, ...allTheProps } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button disabled={isLoading || disabled} {...allTheProps}>
      {children}
      {isLoading && <CircularProgressButton size={20} />}
    </Button>
  );
}

LoadingButton.defaultProps = {
  disabled: false,
};

LoadingButton.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default LoadingButton;
