import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';

import HubjectContractsIcon from '../Icons/HubjectContractsIcon';
import StationTypeListIcon from '../Icons/StationTypeListIcon';

import { MenuTitle, NavLinkStyled, NavIcon, NavText } from './Menu.parts';

function SettingsMenu({ handleClose }) {
  const { t } = useTranslation(['translation', 'settings', 'stationtypes', 'userManagement']);

  return (
    <>
      <MenuTitle variant="h1">{t('Einstellungen')}</MenuTitle>
      <NavLinkStyled to="/users" permission="users:read" onClick={handleClose}>
        <NavIcon>
          <GroupOutlinedIcon />
        </NavIcon>
        <NavText variant="h2"> {t('userManagement:Benutzerverwaltung')}</NavText>
      </NavLinkStyled>
      <NavLinkStyled to="/hubject-contracts" permission="hubject:read" onClick={handleClose}>
        <NavIcon>
          <HubjectContractsIcon />
        </NavIcon>
        <NavText variant="h2"> {t('settings:Hubject Verträge')}</NavText>
      </NavLinkStyled>
      <NavLinkStyled to="/stationtypes" permission="stationtypes:read" onClick={handleClose}>
        <NavIcon>
          <StationTypeListIcon />
        </NavIcon>
        <NavText variant="h2"> {t('stationtypes:Stationstypen')}</NavText>
      </NavLinkStyled>
    </>
  );
}

SettingsMenu.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default SettingsMenu;
