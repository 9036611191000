import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import NavLinkPermission from './NavLinkPermission';

export const MenuTitle = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  margin: ${(props) => props.theme.spacing(0, 3, 1.5)};
  text-align: center;
`;

export const NavLinkStyled = styled(NavLinkPermission)`
  display: flex;
  flex-direction: row;
  padding-left: ${(props) => props.theme.spacing(7)}px;
  height: 80px;
  align-items: center;
  text-decoration: none;
  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.main};
  }
`;

export const NavIcon = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  margin-right: ${(props) => props.theme.spacing(3)}px;
  justify-content: center;
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 100%;
  align-items: center;

  > svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: ${(props) => props.theme.palette.primary.contrastText};
  }
`;

export const NavText = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-weight: 400;
`;
