import update from 'immutability-helper';

import { ADD_TAB, UPDATE_TAB, CLOSE_TAB, CLOSE_ALL_TABS } from '../actions/types';

const initialState = {
  open: [],
};

const tabReducers = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TAB: {
      const tabIndex = state.open && state.open.find((tab) => tab.url === action.payload.url);
      if (!tabIndex) {
        return update(state, {
          open: { $push: [action.payload] },
        });
      }

      return state;
    }
    case UPDATE_TAB: {
      const tabIndex = state.open && state.open.findIndex((tab) => tab.url === action.payload.url);
      if (tabIndex >= 0) {
        return update(state, {
          open: {
            [tabIndex]: { $merge: action.payload },
          },
        });
      }
      return state;
    }
    case CLOSE_TAB:
      return update(state, {
        open: {
          $set: state.open.filter((tab) => tab.url !== action.url),
        },
      });
    case CLOSE_ALL_TABS:
      return update(state, { $set: initialState });
    default:
      return state;
  }
};

export default tabReducers;
