import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Logo, Title } from './NavigationBar.parts';

const Link = styled(NavLink)`
  padding: ${(props) => props.theme.spacing(3, 0)};
  position: relative;
`;

export default function EemLogo({ open, delay }) {
  return (
    <Link to="/">
      <Logo open={open} />
      <Title variant="h2" open={open} delay={delay}>
        Portal
      </Title>
    </Link>
  );
}

EemLogo.propTypes = {
  open: PropTypes.bool.isRequired,
  delay: PropTypes.number.isRequired,
};
