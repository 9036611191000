import styled from 'styled-components/macro';
import { DialogActions, DialogContent, DialogTitle, Paper } from '@material-ui/core';

export const DialogImage = styled.div`
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
  > svg {
    min-width: 200px;
    max-width: 260px;
  }
`;

export const PaperStyled = styled(Paper)`
  background-color: ${(props) => props.theme.palette.background.default};
`;

export const DialogText = styled.div`
  text-align: center;
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 300px;
  overflow-y: visible;

  ${(props) => props.theme.breakpoints.down('sm')} {
    min-width: 200px;
  }
`;

export const DialogActionsStyled = styled(DialogActions)`
  margin: ${(props) => props.theme.spacing(1, 0, 4)};
`;

export const DialogTitleStyled = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
