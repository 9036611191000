import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const FooterContainer = styled.footer`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.paper};
  padding: 0 ${(props) => props.theme.spacing(4)}px;
  min-height: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;
  align-items: center;

  > a:not(:last-of-type) {
    padding-right: ${(props) => props.theme.spacing(8)}px;
  }
`;

function Footer() {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <Link component={RouterLink} color="inherit" variant="body1" to="/impressum">
        {t('Impressum')}
      </Link>
      <Link component={RouterLink} color="inherit" variant="body1" to="/datenschutz">
        {t('Datenschutz')}
      </Link>
    </FooterContainer>
  );
}

export default Footer;
