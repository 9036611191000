/* eslint-disable react/jsx-props-no-spreading */
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { usePermissions } from '../../utils/PermissionsProvider';

export default function NavLinkPermission({ permission, children, ...props }) {
  const { hasPermissions } = usePermissions();

  if (hasPermissions(permission)) {
    return <NavLink {...props}>{children}</NavLink>;
  }

  return null;
}

NavLinkPermission.defaultProps = {
  permission: null,
};

NavLinkPermission.propTypes = {
  children: PropTypes.node.isRequired,
  permission: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
