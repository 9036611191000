import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Avatar, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import { NavlistItem } from './NavigationBar.parts';

const UserNavListItem = styled(NavlistItem)`
  padding: ${(props) => props.theme.spacing(1, 0)} !important;
`;

const AnimatedAvatar = styled(Avatar)`
  width: ${(props) => (props.open ? 48 : 30)}px;
  height: ${(props) => (props.open ? 48 : 30)}px;
  position: absolute;
  left: ${(props) => (props.open ? 12 : 14)}px;
  top: 50%;
  transform: translateY(-50%);

  transition: ${(props) =>
    props.theme.transitions.create(['width', 'height', 'bottom', 'left', 'transform'], {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.enteringScreen,
    })};
`;

const UserName = styled(Typography)`
  opacity: ${(props) => (props.open ? 1 : 0)};
  margin-left: ${(props) => (props.open ? 70 : 0)}px;
  text-transform: uppercase;
  word-break: break-word;
  overflow: hidden;

  ${(props) =>
    props.open
      ? `opacity: 1;
   transition: ${props.theme.transitions.create('opacity', {
     easing: props.theme.transitions.easing.sharp,
     duration: props.theme.transitions.duration.enteringScreen,
     delay: props.delay,
   })};`
      : `opacity: 0;
         visibility: collapse;`};
`;

const UserNavLink = styled(NavLink)`
  display: flex;
  color: ${(props) => props.theme.palette.navbar.text};
  align-items: center;
  width: 100%;
  height: 50px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
`;

export default function UserAvatar({ name, picture, open, delay }) {
  return (
    <UserNavListItem button>
      <UserNavLink to="/profile">
        <AnimatedAvatar alt={name} src={picture} open={open}>
          {name.length > 0 && name.charAt(0)}
        </AnimatedAvatar>
        <UserName variant="h4" open={open} delay={delay}>
          {name}
        </UserName>
      </UserNavLink>
    </UserNavListItem>
  );
}

UserAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  delay: PropTypes.number.isRequired,
};
