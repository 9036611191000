import { useState, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import { Dialog, Paper, useMediaQuery } from '@material-ui/core';

import constants from '../../config/constants';

import SelectAction from './SelectAction';
import LoadingAction from './LoadingAction';

const ContractWizard = lazy(() => import(/* webpackChunkName: "ContractWizard" */ './ContractWizard'));
const StationWizard = lazy(() => import(/* webpackChunkName: "StationWizard" */ './StationWizard'));
const ClusterWizard = lazy(() => import(/* webpackChunkName: "ClusterWizard" */ './ClusterWizard'));
const StationTypeWizard = lazy(() => import(/* webpackChunkName: "StationTypeWizard" */ './StationTypeWizard'));
const UserWizard = lazy(() => import(/* webpackChunkName: "UserWizard" */ './UserWizard'));

const PaperStyled = styled(Paper)`
  background-color: ${(props) => props.theme.palette.background.default};
`;

export default function WizardDialog({
  disableBackdropClick,
  disableEscapeKeyDown,
  maxWidth,
  selectedAction,
  open,
  options,
  handleClose,
}) {
  const [action, setAction] = useState(selectedAction);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const closeDialog = () => {
    handleClose();
  };

  const changeAction = (type) => () => {
    setAction(type);
  };

  const discardAction = () => {
    setAction('');
  };

  const renderActionComponent = () => {
    switch (action) {
      case constants.accessCategories.HOME:
        return (
          <StationWizard
            accessCategory={constants.accessCategories.HOME}
            options={options}
            handleDiscard={discardAction}
            handleClose={closeDialog}
          />
        );
      case constants.accessCategories.BUSINESS:
        return (
          <StationWizard
            accessCategory={constants.accessCategories.BUSINESS}
            options={options}
            handleDiscard={discardAction}
            handleClose={closeDialog}
          />
        );
      case constants.CONTRACT:
        return <ContractWizard options={options} handleDiscard={discardAction} handleClose={closeDialog} />;
      case constants.stationClusterPannels.STATIONSCLUSTER:
        return <ClusterWizard options={options} handleDiscard={discardAction} handleClose={closeDialog} />;
      case constants.STATIONTYPE:
        return <StationTypeWizard options={options} handleClose={closeDialog} />;
      case constants.USERMANAGEMENT:
        return <UserWizard options={options} handleClose={closeDialog} />;
      default:
        return <SelectAction handleClick={changeAction} />;
    }
  };

  return (
    <Dialog
      disableBackdropClick={disableBackdropClick && action !== ''}
      disableEscapeKeyDown={disableEscapeKeyDown && action !== ''}
      open={open}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      onClose={closeDialog}
      aria-labelledby="action-dialog"
      PaperComponent={PaperStyled}
      data-testid="action-dialog-wrapper"
    >
      <Suspense fallback={<LoadingAction />}>{renderActionComponent()}</Suspense>
    </Dialog>
  );
}

WizardDialog.defaultProps = {
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
  maxWidth: 'lg',
  selectedAction: '',
  options: null,
};

WizardDialog.propTypes = {
  selectedAction: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};
