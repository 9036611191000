import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconButton, Snackbar, Typography, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';

import constants from '../../config/constants';
import icons from '../../utils/iconSwticher';

const Message = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const SnackbarContentVariant = styled(SnackbarContent)`
  background-color: ${(props) => props.theme.palette.common.white};
`;

const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  background-color: ${(props) => props.theme.palette.background.light};
  border-radius: 50%;
  margin-right: ${(props) => props.theme.spacing(3)}px;

  svg {
    height: 4.5rem;
    width: auto;
  }
`;

function SnackbarNotification({ duration, message, id, open, type, onClose, title }) {
  const { t } = useTranslation();

  const Icon = icons[type] || SuccessIcon;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={duration}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      key={id}
      open={open}
      onClose={onClose}
    >
      <SnackbarContentVariant
        message={
          <Message id="client-snackbar">
            <IconWrapper>
              <Icon />
            </IconWrapper>
            <div>
              {title && (
                <Typography id="title-id" variant="h3">
                  {title}
                </Typography>
              )}
              <Typography id="message-id" variant="body1">
                {message}
              </Typography>
            </div>
          </Message>
        }
        action={[
          <IconButton key="close" aria-label={t('Schließen')} color="secondary" size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

SnackbarNotification.defaultProps = {
  duration: 6000,
  open: null,
  type: constants.icons.SUCCESS,
  title: '',
};

SnackbarNotification.propTypes = {
  duration: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default SnackbarNotification;
