import { useState } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';

import CarIcon from '../Icons/CarIcon';
import StationClusterIcon from '../Icons/StationClusterIcon';
import PopoverMenuLayout from '../../layout/PopoverMenuLayout';
import { useAuth0 } from '../../utils/Auth0Provider';
import { useWizard } from '../../utils/WizardProvider';
import Footer from '../Footer';
import UserHas from '../../utils/UserHas';

import SearchMenu from './SearchMenu';
import AnimatedDrawer from './AnimatedDrawer';
import UserAvatar from './UserAvatar';
import EemLogo from './EemLogo';
import NavigationLink from './NavigationLink';
import LogoutButton from './LogoutButton';
import {
  NavBarToggleButton,
  NavList,
  useStyles,
  NavlistItem,
  NavListIcon,
  NavigationText,
  NavDivider,
} from './NavigationBar.parts';
import SettingsMenu from './SettingsMenu';

export const getDrawerState = () => {
  try {
    const drawerState = localStorage.getItem('navbarDrawerState');
    return drawerState === 'true';
  } catch (err) {
    return false;
  }
};

export const saveDrawerState = (status) => {
  try {
    localStorage.setItem('navbarDrawerState', status);
  } catch {
    // ignore write errors
  }
};

const userInfo = `${process.env.REACT_APP_AUTH_0_NAMESPACE}userInfo`;

export default function NavigationBar({ children }) {
  const { t } = useTranslation(['translation', 'search', 'cluster']);
  const classes = useStyles();
  const [open, setOpen] = useState(getDrawerState());
  const [isPopperOpen, setIsPopoverOpen] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { user, isAuthenticated, loading } = useAuth0();
  const { openWizard } = useWizard();

  let userName = '';
  let userPicture = '';

  if (user) {
    userName = user[userInfo]?.name || user.email;
    userPicture = user.picture;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
    saveDrawerState(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    saveDrawerState(false);
  };

  const openWizardDialog = () => {
    openWizard();
  };

  const handleOpenPopper = (event, type) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(type);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setIsPopoverOpen(null);
  };

  if (loading || !isAuthenticated) {
    return children;
  }

  return (
    <>
      <div className={classes.root}>
        <AnimatedDrawer open={open}>
          <NavBarToggleButton data-testid="toggleButton" onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? (
              <ChevronLeftIcon className={classes.chevronIcon} />
            ) : (
              <ChevronRightIcon className={classes.chevronIcon} />
            )}
          </NavBarToggleButton>
          <EemLogo open={open} delay={40} />
          <NavList component="nav">
            <UserHas permission={['stations:read', 'contracts:read', 'customers:read', 'cdrs:read']}>
              <NavlistItem button onClick={(e) => handleOpenPopper(e, 'search')}>
                <NavListIcon>
                  <SearchIcon />
                </NavListIcon>
                <NavigationText open={open} disableTypography primary={t('search:Suche')} delay={80} />
              </NavlistItem>
            </UserHas>
            <NavigationLink
              icon={StationClusterIcon}
              to="/stationclusters"
              text={t('cluster:Stationscluster')}
              open={open}
              delay={120}
              permission="stationcluster:read"
            />
            <UserHas permission="vehicles:read">
              <NavigationLink icon={CarIcon} to="/vehicles" text={t('translation:Vehicles')} open={open} delay={120} />
            </UserHas>
            <UserHas permission={['hubject:read', 'stationtypes:read', 'users:read']}>
              <NavlistItem button onClick={(e) => handleOpenPopper(e, 'settings')}>
                <NavListIcon>
                  <SettingsIcon />
                </NavListIcon>
                <NavigationText open={open} disableTypography primary={t('Einstellungen')} delay={80} />
              </NavlistItem>
            </UserHas>
            <UserHas permission={['stations:create', 'contracts:create', 'stationcluster:create']}>
              <NavDivider light={true} variant="middle" />
              <NavlistItem button onClick={openWizardDialog}>
                <NavListIcon>
                  <AddCircleOutlineIcon />
                </NavListIcon>
                <NavigationText open={open} disableTypography primary={t('Erstellen')} delay={200} />
              </NavlistItem>
            </UserHas>
          </NavList>
          <NavList component="nav">
            <UserAvatar name={userName} picture={userPicture} open={open} delay={240} />
            <LogoutButton text={t('Logout')} open={open} delay={280} />
          </NavList>
        </AnimatedDrawer>
        <main className={classes.pageWrapper}>
          <div className={classes.pageContent}>{children}</div>
          <Footer />
        </main>
      </div>
      {isPopperOpen === 'search' && (
        <PopoverMenuLayout isOpen={true} anchorEl={anchorEl} handleClose={handleClosePopover}>
          <SearchMenu handleClose={handleClosePopover} />
        </PopoverMenuLayout>
      )}
      {isPopperOpen === 'settings' && (
        <PopoverMenuLayout isOpen={true} anchorEl={anchorEl} handleClose={handleClosePopover}>
          <SettingsMenu handleClose={handleClosePopover} />
        </PopoverMenuLayout>
      )}
    </>
  );
}

NavigationBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
