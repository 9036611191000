import axios from 'axios';
import queryString from 'query-string';

import constants from '../config/constants';

import { setupAuth0Client } from './Auth0Provider';

let token = null;

async function setupAuthorizationHeaders() {
  if (!token) {
    const auth0Client = await setupAuth0Client();
    token = await auth0Client.getTokenSilently();
  }

  return {
    Authorization: `Bearer ${token}`,
  };
}

const api = axios.create({
  baseURL: process.env.REACT_APP_EPOWER_BACKEND,
  headers: {
    accept: 'application/json',
    'x-epower-authentication-provider': 'auth0',
    assumedRole: 'ROLE_OPERATOR_BASE',
  },
});

export const apiResponseInterceptor = async (config) => {
  if (config) {
    const newConfig = config;
    const newHeaders = await setupAuthorizationHeaders();
    newConfig.headers = {
      ...config.headers,
      ...newHeaders,
    };
    return newConfig;
  }
  return config;
};

// Disable interceptors for testing
if (process.env.NODE_ENV !== 'test') {
  api.interceptors.request.use(
    async (config) => {
      const newConfig = config;
      const newHeaders = await setupAuthorizationHeaders();
      newConfig.headers = {
        ...config.headers,
        ...newHeaders,
      };
      return newConfig;
    },
    (error) => {
      Promise.reject(error);
    }
  );
}

export default api;

/* --- CSV Specific header --- */

const csvHeader = {
  headers: {
    accept: 'text/csv',
    'x-epower-authentication-provider': 'auth0',
    ContentType: 'text/csv',
  },
};

/* --- Permission handling --- */

export async function updatePermissions() {
  return api.get(`/web/v1/permissions`);
}

/* --- GET requests --- */

export async function getStationByUuid(stationUuid) {
  return api.get(`/web/v1/stations/${stationUuid}`);
}

export async function getContractById(id) {
  return api.get(`/web/v1/contracts/${id}`);
}

export async function getContractByHomestationUuid(stationUuid) {
  return api.get(`/web/v1/contracts/homestation/${stationUuid}`);
}

export async function getContractByEvcoNumber(evcoNumber) {
  return api.get(`/web/v1/contracts/evco/${evcoNumber}`);
}

export async function getContracts(searchParams) {
  const params = {
    size: 10,
    page: 0,
    ...searchParams,
  };
  return api.get(`/web/v1/contracts`, { params });
}

export async function getAllContractsByCustomer(searchParams) {
  const { customer, ...restParams } = searchParams;
  const params = {
    size: 10,
    page: 0,
    ...restParams,
  };
  return api.get(`/web/v1/customers/${customer}/contracts`, { params });
}

export async function getCdrs(searchParams) {
  const params = {
    size: 10,
    page: 0,
    ...searchParams,
  };
  return api.get('/web/v1/customers/cdrs', {
    params,
    paramsSerializer: (prmtr) => queryString.stringify(prmtr, { arrayFormat: 'comma', skipNull: true }),
  });
}

export async function setChargingModeToEmsPowerMode(spotUuid) {
  return api.get(`/web/v1/spots/${spotUuid}/emspowermode`);
}

export async function getAllActiveSessionsForStationByUuid(stationUuid) {
  return api.get(`/web/v1/stations/${stationUuid}/sessions/spots/active`);
}

export async function getActiveSessionForContractId(contractId) {
  return api.get(`/web/v1/contracts/${contractId}/sessions/active`);
}

export async function getOcppConnectionStatus(stationUuid) {
  return api.get(`/web/v1/ocpp/stations/${stationUuid}/status`);
}

export async function getStationGroups(search, page = 0, size = 50, filter = '') {
  return api.get(`/web/v1/stationgroups?page=${page}&size=${size}&sort=name&search=${search}&filter=${filter}`);
}

export async function getCustomers(searchParams) {
  const params = {
    size: 10,
    page: 0,
    sort: 'name',
    ...searchParams,
  };
  return api.get(`/web/v1/customers`, { params });
}

export async function getCustomerById(customerId) {
  return api.get(`/web/v1/customers/${customerId}`);
}

export async function getStationClusters(searchParams) {
  const params = {
    size: 30,
    page: 0,
    search: '',
    ...searchParams,
  };
  return api.get(`/web/v1/stationclusters`, { params });
}

export async function getStationClusterForStation(stationUuid) {
  return api.get(`/web/v1/station/${stationUuid}/stationcluster`);
}

/**
 *
 * @param {{
 *   search: String,
 *   page: number,
 *   lifecycleStates: String,
 *   accessCategories: String,
 *   sort: String
 *   size, number
 * }} params The parameters of the search object
 */
export async function getStations(params) {
  return api.get(`/web/v1/stations/search`, {
    params,
    paramsSerializer: (prmtr) => queryString.stringify(prmtr, { skipNull: true }),
  });
}

export async function getStationLogs(stationUuid, params) {
  return api.get(`/web/v1/stations/${stationUuid}/logs`, {
    params,
    paramsSerializer: (prmtr) => queryString.stringify(prmtr, { arrayFormat: 'comma', skipNull: true }),
  });
}

export async function getFilteredSortedCDRsByContractId(contractId, body, params) {
  return api.get(`/web/v1/contracts/${contractId}/cdrs`, {
    params,
    paramsSerializer: (prmtr) => queryString.stringify(prmtr, { arrayFormat: 'comma' }),
  });
}

export async function getSimCardInfoByIccid(iccid) {
  return api.get(`/web/v1/stations/simcard/${iccid}/simcardinfo`);
}

export async function searchStationTypes(searchParams) {
  // We need this in order to handle all search endpoints the same from frontend perspective
  const { search, ...rest } = searchParams;
  const params = {
    size: 10,
    page: 0,
    ...rest,
    searchTerm: search || '',
  };
  return api.get(`/web/v1/stationtypes/search`, { params });
}

export async function validateIccId(iccid) {
  return api.get(`/web/v1/sim/${iccid}/validate`);
}

export async function getHubjectContracts(searchParams) {
  const params = {
    size: 10,
    page: 0,
    sort: 'initial,desc',
    search: '',
    ...searchParams,
  };
  return api.get(`/web/v1/hubject-operators`, { params });
}

export async function getStationPois(params) {
  return api.get(`/web/v1/stations/pois`, {
    params,
    paramsSerializer: (prmtr) => queryString.stringify(prmtr, { arrayFormat: 'comma', skipNull: true }),
  });
}

export async function getClusterStations(stationUuids) {
  const params = {
    stationUuids,
  };
  return api.get(`/web/v1/cpo/lmsStations`, { params });
}

export async function getStationClusterById(id) {
  return api.get(`/web/v1/stationclusters/${id}`);
}

export async function getOcppStations(searchParams) {
  const params = {
    size: 5,
    page: 0,
    sort: 'chargeBoxId',
    search: '',
    lifecycleStates: 'OPERATIVE,OUT_OF_ORDER',
    accessCategories: 'BUSINESS',
    ...searchParams,
  };
  return api.get(`/web/v1/cpo/stations`, { params });
}

export async function getStationTypeById(id) {
  return api.get(`/web/v1/stationtypes/${id}`);
}

export async function getCdrById(id) {
  return api.get(`/web/v1/customers/cdrs/${id}`);
}

export async function getStationTypeBySpotId(id) {
  return api.get(`/web/v1/stations/evseid/${id}`);
}

export async function searchUsers(searchParams) {
  const params = {
    size: 10,
    page: 0,
    ...searchParams,
  };
  return api.get(`/web/v1/users`, {
    params,
    paramsSerializer: (prmtr) => queryString.stringify(prmtr, { skipNull: true }),
  });
}

export async function getAllRoles() {
  return api.get(`/web/v1/roles`);
}

export async function getUser(auth0UserId) {
  return api.get(`/web/v1/users/${auth0UserId}`);
}

export async function getAllCars() {
  return api.get('/web/v1/cars');
}

export async function addCarToContract(customerId, contractId, carId) {
  return api.get(`/web/v1/customers/${customerId}/contracts/${contractId}/car/${carId}`);
}

export async function getCustomerDocuments(customerId) {
  return api.get(`/web/v1/customers/${customerId}/customerdocuments`);
}

export async function downloadCustomerDocument(customerDocumentId) {
  const downloadFileConfig = {
    headers: {
      accept: '*/*',
      'x-epower-authentication-provider': 'auth0',
    },
    responseType: 'blob',
    timeout: 30000,
  };
  return api.get(`/web/v1/customerdocuments/${customerDocumentId}`, downloadFileConfig);
}

export async function getProductsForOrganization(customerId) {
  return api.get(`/web/v1/customers/${customerId}/products`);
}

export async function getAllProducts() {
  return api.get(`/web/v1/products`);
}

export async function getVehicles() {
  return api.get(`/web/v1/vehicles`);
}

export async function getInviteLinksByCustomerId(customerId) {
  return api.get(`/web/v1/customers/${customerId}/selfsignuptokens`);
}

export async function getContractsDigitalPrecheck(contractId, digitalPrecheckUuid) {
  return api.get(`/web/v1/contracts/${contractId}/digitalprechecks/${digitalPrecheckUuid}`);
}

/* --- PUT requests --- */

export async function updateStation(stationUuid, body, type) {
  if (type === constants.ETREL_ONE_WAY) {
    return api.put(`/web/v1/etrel/stations/${stationUuid}`, body);
  }
  return api.put(`/web/v1/ocpp/stations/${stationUuid}`, body);
}

export async function setStationLifecycleStateToReady(stationUuid, type = null, body = null) {
  return api.put(`/web/v1/stations/${stationUuid}/setReady/${type}`, body);
}

export async function setStationLifecycleStateToOperative(stationUuid) {
  return api.put(`/web/v1/stations/${stationUuid}/setOperative`);
}

export async function setStationLifecycleStateToOutOfOrder(stationUuid) {
  return api.put(`/web/v1/stations/${stationUuid}/setOutOfOrder`);
}

export async function setStationLifecycleStateToInactive(stationUuid) {
  return api.put(`/web/v1/stations/${stationUuid}/setInactive`);
}

export async function updateDriverForContract(customerId, contractId, driverId, body) {
  return api.put(`/web/v1/customers/${customerId}/contracts/${contractId}/drivers/${driverId}`, body);
}

export async function updateHubjectContracts(operatorId, body) {
  return api.put(`/web/v1/hubject-operators/${operatorId}`, body);
}

export async function deactivateStationClusterById(id) {
  return api.put(`/web/v1/stationclusters/${id}/deactivate`);
}

export async function updateStationClusterById(id, body) {
  return api.put(`/web/v1/stationclusters/${id}`, body);
}

export async function updateUser(auth0UserId, body) {
  return api.put(`/web/v1/users/${auth0UserId}`, body);
}

export async function resetUserPassword(auth0UserId) {
  return api.put(`/web/v1/users/${auth0UserId}/resetpassword`);
}

export async function assignRolesToUser(auth0UserId, body) {
  return api.put(`/web/v1/users/${auth0UserId}/roles`, body);
}

export async function removeRolesForUser(auth0UserId, body) {
  return api.put(`/web/v1/users/${auth0UserId}/roles/delete`, body);
}

export async function updateWhitelistForOcppStation(stationUuid, body) {
  return api.put(`/web/v1/ocpp/stations/${stationUuid}/setlocalauthlistenabled`, body);
}

export async function enableCustomerById(customerId, body) {
  return api.put(`/web/v1/customers/${customerId}/enable`, body);
}

export async function enableFleetManager(customerId, userId) {
  return api.put(`/web/v1/customers/${customerId}/fleetmanagers/${userId}/enable`);
}

/* --- POST requests ---*/

export async function getFilteredSortedCDRs(body, params) {
  return api.post(`/web/v1/spots/cdrs`, body, {
    params,
    paramsSerializer: (prmtr) => queryString.stringify(prmtr, { arrayFormat: 'comma' }),
  });
}

export async function restartStation(stationUuid) {
  return api.post(`/web/v1/stations/${stationUuid}/restart`);
}

export async function remoteStart(stationUuid, spotUuid, body) {
  return api.post(`/web/v1/stations/${stationUuid}/spots/${spotUuid}/remotestart`, body);
}

export async function remoteStop(sessionId) {
  return api.post(`/web/v1/sessions/${sessionId}/stop`);
}

export async function unlockOcppSpot(stationUuid, spotUuid) {
  return api.post(`/web/v1/stations/${stationUuid}/spots/${spotUuid}/unlock`);
}

export async function changeAvailabilityOfSpot(stationUuid, spotUuid, body) {
  return api.post(`/web/v1/stations/${stationUuid}/spots/${spotUuid}/availability`, body);
}

export async function addStationGroupToContract(customerId, contractId, body) {
  return api.post(`/web/v1/customers/${customerId}/contracts/${contractId}/stationgroups`, body);
}

export async function addRoamingStationGroupsToContract(customerId, contractId) {
  return api.post(`/web/v1/customers/${customerId}/contracts/${contractId}/roamingstationgroups`);
}

export async function createRfidForContract(customerId, contractId, body) {
  return api.post(`/web/v1/customers/${customerId}/contracts/${contractId}/rfids`, body);
}

export async function synchronizeContract(contractNumber) {
  return api.post(`/web/v1/contracts/${contractNumber}`);
}

export async function createCluster(body) {
  return api.post('/web/v1/stationclusters', body);
}

export async function resetDriverPassword(contractId, driverId) {
  return api.post(`/web/v1/contracts/${contractId}/drivers/${driverId}/resetpassword`);
}

export async function createStation(body, type = 'ocpp') {
  return api.post(`/web/v1/stations/${type}`, body);
}

export async function getCdrCsvForCustomer(searchParams, body) {
  return api.post(`/web/v1/customers/${searchParams.customerId}/cdrs`, body, csvHeader);
}

export async function getCdrCsvForContract(searchParams, body) {
  return api.post(`/web/v1/contracts/${searchParams.eemContractNumber}/cdrs`, body, csvHeader);
}

export async function getCdrCsvForAllCustomers(body) {
  return api.post(`/web/v1/customers/cdrs`, body, csvHeader);
}

export async function createStationType(body) {
  return api.post(`/web/v1/stationtypes`, body);
}

export async function uploadStationTypeAsset(stationTypeId, file, body) {
  const formData = new FormData();
  formData.append('file', file);
  const blob = new Blob([JSON.stringify(body)], { type: 'application/json' });
  formData.append('request', blob);

  const headers = { 'Content-Type': 'multipart/form-data' };
  return api.post(`/web/v1/stationtypes/${stationTypeId}/stationtypeassets`, formData, { headers });
}

export async function createUser(body) {
  return api.post(`/web/v1/users`, body);
}

export async function uploadCustomerDocument(customerId, file, body) {
  const formData = new FormData();
  formData.append('file', file);
  const blob = new Blob([JSON.stringify(body)], { type: 'application/json' });
  formData.append('request', blob);

  const headers = { 'Content-Type': 'multipart/form-data' };
  return api.post(`/web/v1/customers/${customerId}/customerdocuments`, formData, { headers });
}

export async function createInviteLink(customerId) {
  return api.post(`/web/v1/customers/${customerId}/selfsignuptokens`);
}

export async function addDefaultStationGroupToCustomer(customerId, stationGroupUuid) {
  return api.post(`/web/v1/customers/${customerId}/default-station-groups`, { stationGroupUuid });
}

/* --- DELETE requests ---*/

export async function removeDriverFromContract(driverId, customerId, contractId) {
  return api.delete(`/web/v1/customers/${customerId}/contracts/${contractId}/drivers/${driverId}`);
}

export async function removeStationGroupFromContract(stationGroupId, customerId, contractId) {
  return api.delete(`/web/v1/customers/${customerId}/contracts/${contractId}/stationgroups/${stationGroupId}`);
}

export async function removeRfidFromContract(rfidId, customerId, contractId) {
  return api.delete(`/web/v1/customers/${customerId}/contracts/${contractId}/rfids/${rfidId}`);
}

export async function removeMeterfromOcppStation(stationUuid) {
  return api.delete(`/web/v1/ocpp/stations/${stationUuid}/meter`);
}

export async function removeMeterfromEtrelStation(stationUuid) {
  return api.delete(`/web/v1/etrel/stations/${stationUuid}/meter`);
}

export async function deleteStationType(stationTypeId) {
  return api.delete(`/web/v1/stationtypes/${stationTypeId}`);
}

export async function deleteUser(auth0UserId) {
  return api.delete(`/web/v1/users/${auth0UserId}`);
}

export async function deleteCustomerDocument(customerDocumentId) {
  return api.delete(`/web/v1/customerdocuments/${customerDocumentId}`);
}

export async function deleteInviteLink(customerId, uuid) {
  return api.delete(`/web/v1/customers/${customerId}/selfsignuptokens/${uuid}`);
}

export async function removeDefaultStationGroupFromCustomer(customerId, stationGroupUuid) {
  return api.delete(`/web/v1/customers/${customerId}/default-station-groups/${stationGroupUuid}`);
}

/* --- PATCH requests ---*/

export async function updateContract(contractId, body) {
  return api.patch(`/web/v1/contracts/${contractId}`, body);
}

export async function updateDigitalPrecheckStatus(contractId, digitalPrecheckUuid, status) {
  return api.patch(`/web/v1/contracts/${contractId}/digitalprechecks/${digitalPrecheckUuid}`, { status });
}

export async function updateDigitalPrecheckReport(contractId, digitalPrecheckUuid) {
  return api.patch(`/web/v1/contracts/${contractId}/digitalprechecks/${digitalPrecheckUuid}/report`);
}
