const auth = {
  domain: process.env.REACT_APP_AUTH_0_ISSUER,
  client_id: process.env.REACT_APP_AUTH_0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH_0_AUDIENCE,
  redirect_uri: window.location.origin,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
};

export default auth;
