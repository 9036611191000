import styled from 'styled-components/macro';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  Divider,
} from '@material-ui/core';

import { ReactComponent as EemobilityIcon } from '../../assets/icons/eemLogo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  pageWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  pageContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: 224,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.navbar.main,
    overflow: 'visible',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 56,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.navbar.main,
    overflow: 'visible',
  },
  chevronIcon: {
    height: 18,
    width: 18,
  },
  navLink: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.navbar.text,
    textDecoration: 'none',
  },
  activeNavLink: {
    transition: theme.transitions.create(['color', 'borderLeft'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.navbar.activeText,
    textDecoration: 'none',
    borderLeft: '4px solid #68edcb',
  },
}));

const NavigationText = styled(ListItemText)`
  margin-top: 12px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  text-transform: uppercase;
  ${(props) =>
    props.open
      ? `opacity: 1;
   transition: ${props.theme.transitions.create('opacity', {
     easing: props.theme.transitions.easing.sharp,
     duration: props.theme.transitions.duration.enteringScreen,
     delay: props.delay,
   })};`
      : `opacity: 0;
         visibility: collapse;`};
`;

const NavlistItem = styled(ListItem)`
  color: ${(props) => props.theme.palette.navbar.text};
  padding: 0;
`;

const NavListIcon = styled(ListItemIcon)`
  color: currentColor;
  width: 16px;
  height: 16px;
  padding-left: 16px;
`;

const NavBarToggleButton = styled(IconButton)`
  position: absolute;
  border-radius: 50%;
  line-height: 50%;
  top: 64px;
  right: -13px;
  width: 25px;
  height: 25px;
  z-index: 1500;
  background-color: ${(props) => props.theme.palette.primary.main};
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
  color: ${(props) => props.theme.palette.primary.contrastText};
  padding: 0;
`;

const NavList = styled(List)`
  display: flex;
  flex-direction: column;

  > div {
    padding: ${(props) => props.theme.spacing(1, 0)};
    ${(props) => props.theme.breakpoints.up('sm')} {
      padding: ${(props) => props.theme.spacing(2, 0)};
    }
  }
`;

const Logo = styled(EemobilityIcon)`
  color: ${(props) => props.theme.palette.common.white};
  position: absolute;
  width: ${(props) => (props.open ? '55' : '35')}px;
  top: ${(props) => (props.open ? '15' : '10')}px;
  left: ${(props) => (props.open ? '22' : '11')}px;
  /* top: ${(props) => (props.open ? '2.4' : '1.5')}%;
  left: ${(props) => (props.open ? '9.3' : '17.5')}%; */

  transition: ${(props) =>
    props.theme.transitions.create(['width', 'top', 'left'], {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.enteringScreen,
    })};
  ${(props) => props.theme.breakpoints.down('xs')} {
    width: 35px;
  }
`;

const Title = styled(Typography)`
  position: absolute;
  margin: 0;
  left: 97px;
  top: 35px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.navbar.text};
  opacity: ${(props) => (props.open ? 1 : 0)};
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};

  transition: ${(props) =>
    props.theme.transitions.create('opacity, visibility', {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.enteringScreen,
      delay: props.delay,
    })};

  ${(props) => props.theme.breakpoints.down('xs')} {
    visibility: hidden;
  }
`;

const NavDivider = styled(Divider)`
  margin-top: ${(props) => props.theme.spacing(1)}px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
`;

export { useStyles, NavlistItem, NavBarToggleButton, NavigationText, NavListIcon, NavList, Title, Logo, NavDivider };
