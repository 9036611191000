import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import WizardDialog from '../components/Wizards/WizardDialog';

export const WizardContext = createContext();
export const useWizard = () => useContext(WizardContext);

export function WizardProvider({ children }) {
  const [wizard, setWizard] = useState({});

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const openWizard = useCallback((action = '', options = null) => {
    setWizard({
      action,
      options,
    });
  }, []);

  const closeWizard = useCallback(() => {
    setWizard({});
  }, []);

  const handleOnCloseWizard = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      closeWizard();
    },
    [closeWizard]
  );

  const value = useMemo(
    () => ({
      openWizard,
      closeWizard,
    }),
    [openWizard, closeWizard]
  );

  return (
    <WizardContext.Provider value={value}>
      {children}
      {Object.keys(wizard).length > 0 && (
        <WizardDialog
          fullScreen={fullScreen}
          open={Object.keys(wizard).length > 0}
          options={wizard.options}
          selectedAction={wizard.action}
          handleClose={(event, reason) => {
            handleOnCloseWizard(event, reason);
          }}
        />
      )}
    </WizardContext.Provider>
  );
}

WizardProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
