import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Drawer } from '@material-ui/core';

import { useStyles } from './NavigationBar.parts';

export default function AnimatedDrawer({ children, open }) {
  const { drawerOpen, drawerClose } = useStyles();

  return (
    <Drawer
      variant="permanent"
      className={clsx({
        [drawerOpen]: open,
        [drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [drawerOpen]: open,
          [drawerClose]: !open,
        }),
      }}
      open={open}
    >
      {children}
    </Drawer>
  );
}

AnimatedDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
