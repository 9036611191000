import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ReactComponent as Icon } from '../../assets/icons/publicStation.svg';

export default function PublicStationIcon({ tooltipEnabled }) {
  const { t } = useTranslation('accessCategories');

  if (tooltipEnabled) {
    return (
      <Tooltip title={t('PUBLIC')} placement="bottom-start">
        <Icon className="MuiSvgIcon-root" alt="category-image" />
      </Tooltip>
    );
  }

  return <Icon className="MuiSvgIcon-root" alt="category-image" />;
}

PublicStationIcon.defaultProps = {
  tooltipEnabled: false,
};

PublicStationIcon.propTypes = {
  tooltipEnabled: PropTypes.bool,
};
