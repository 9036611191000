import { createStore, applyMiddleware, compose } from 'redux';
import { throttle } from 'throttle-debounce';

import reducer from './reducers';
import { loadState, saveState } from './localStorage';

const persistedState = loadState() || {};

const enhancers = [];
const middleware = [];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-underscore-dangle
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(reducer, persistedState, composedEnhancers);

store.subscribe(
  throttle(1000, () => {
    saveState({
      tabs: store.getState().tabs,
    });
  })
);

export default store;
