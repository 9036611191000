import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Backdrop, Popover } from '@material-ui/core';

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    background-color: ${(props) => props.theme.palette.common.white};

    ${(props) => props.theme.breakpoints.up('sm')} {
      margin-left: 1rem;
      overflow: auto;
      scrollbar-width: none;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      overflow: -moz-scrollbars-none;
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        transform: translateX(-100%);
        left: 0;
        top: 30px;
        border: 8px solid transparent;
        border-right-color: ${(props) => props.theme.palette.common.white};
      }
    }
  }
`;

const Content = styled.div`
  min-width: 300px;
  padding: ${(props) => props.theme.spacing(4, 0, 2)};

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(3, 0, 2)};
  }
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: 1201;
`;

function PopoverMenuLayout({ isOpen, children, anchorEl, handleClose }) {
  return (
    <>
      <StyledBackdrop open={isOpen} data-testid="transparent-layer" onClick={handleClose} />
      <StyledPopover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <Content>{children}</Content>
      </StyledPopover>
    </>
  );
}

PopoverMenuLayout.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PopoverMenuLayout;
