import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ReactComponent as Icon } from '../../assets/icons/homeStation.svg';

export default function HomeStationIcon({ tooltipEnabled }) {
  const { t } = useTranslation('accessCategories');

  if (tooltipEnabled) {
    return (
      <Tooltip title={t('HOME')} placement="bottom-start">
        <Icon className="MuiSvgIcon-root" alt="category-image" />
      </Tooltip>
    );
  }

  return <Icon className="MuiSvgIcon-root" alt="category-image" />;
}

HomeStationIcon.defaultProps = {
  tooltipEnabled: false,
};

HomeStationIcon.propTypes = {
  tooltipEnabled: PropTypes.bool,
};
