import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { useAuth0 } from '../../utils/Auth0Provider';

import './LoginForm.css';

const LoginContent = styled.div`
  font-family: 'Nunito Sans', Arial, sans-serif;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.spacing(0, 2)};
`;

const Title = styled.h1`
  color: #034911;
  font-size: 44px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  margin: 0;
`;

const SubTitle = styled.h3`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 1.2rem;
  margin: 0 0 2rem 0;
`;

const Submit = styled(Button)`
  font-size: 1rem;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  max-width: 25rem;
  margin: 2rem 0 0;
  padding: ${(props) => props.theme.spacing(2, 3)};

  .MuiButton-label {
    position: relative;
  }

  .MuiButton-endIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  .MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
  }
`;

const LoginError = styled(Typography)`
  text-align: center;
  cursor: pointer;
`;

function LoginForm() {
  const { loginWithRedirect, logout, getErrorMessage } = useAuth0();
  const { t } = useTranslation();

  const loginErrorMessage = getErrorMessage();

  const auth0LoginHandler = () => {
    loginWithRedirect({});
  };

  return (
    <LoginContent>
      <Title>ePower Portal</Title>
      <SubTitle>{t('Herzlich Willkommen!')}</SubTitle>
      {loginErrorMessage ? (
        <LoginError variant="body1" color="error" onClick={() => logout()}>
          {t(loginErrorMessage)}
        </LoginError>
      ) : (
        <Submit
          fullWidth
          color="primary"
          endIcon={<ArrowForwardIosIcon fontSize="small" />}
          size="large"
          type="button"
          variant="outlined"
          onClick={auth0LoginHandler}
        >
          {t('Login')}
        </Submit>
      )}
    </LoginContent>
  );
}

export default LoginForm;
