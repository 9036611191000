import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function PageLoadingSpinner() {
  return (
    <Container data-testid="page-loading-spinner">
      <CircularProgress color="primary" />
    </Container>
  );
}
