import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography, List, ListItem, ListItemIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import BusinessStationIcon from '../Icons/BusinessStationIcon';
import HomeStationIcon from '../Icons/HomeStationIcon';
import StationClusterIcon from '../Icons/StationClusterIconFilled';
import constants from '../../config/constants';
import UserHas from '../../utils/UserHas';

const MenuWrapper = styled.div`
  margin: ${(props) => props.theme.spacing(4, 0)};
  padding: ${(props) => props.theme.spacing(0)};
`;

export const MenuTitle = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  margin: ${(props) => props.theme.spacing(0, 3, 1.5)};
  text-align: center;
`;

const IconWrapper = styled.div`
  color: ${(props) => props.theme.palette.primary.contrastText};
  width: 45px;
  height: 45px;
  background-color: ${(props) => props.theme.palette.primary.main};
  margin-right: ${(props) => props.theme.spacing(3)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  > svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: ${(props) => props.theme.palette.primary.contrastText};
  }
`;

const ListItemStyled = styled(ListItem)`
  display: flex;
  flex-direction: row;
  padding-left: ${(props) => props.theme.spacing(7)}px;
  height: 80px;
  align-items: center;
  text-decoration: none;
  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ListItemText = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-weight: 400;
`;

function SelectAction({ handleClick }) {
  const { t } = useTranslation(['wizard', 'accessCategories', 'contracts', 'cluster']);

  return (
    <MenuWrapper>
      <MenuTitle variant="h1">{t('Was möchtest du anlegen?')}</MenuTitle>

      <List>
        <UserHas permission="stations:create">
          <ListItemStyled disableGutters button onClick={handleClick(constants.accessCategories.HOME)}>
            <ListItemIcon>
              <IconWrapper>
                <HomeStationIcon />
              </IconWrapper>
            </ListItemIcon>
            <ListItemText variant="h2">{t('accessCategories:HOME')}</ListItemText>
          </ListItemStyled>
          <ListItemStyled disableGutters button onClick={handleClick(constants.accessCategories.BUSINESS)}>
            <ListItemIcon>
              <IconWrapper>
                <BusinessStationIcon />
              </IconWrapper>
            </ListItemIcon>
            <ListItemText variant="h2">{t('accessCategories:BUSINESS')}</ListItemText>
          </ListItemStyled>
        </UserHas>
        <UserHas permission="contracts:create">
          <ListItemStyled disableGutters button onClick={handleClick(constants.CONTRACT)}>
            <ListItemIcon>
              <IconWrapper>
                <PersonAddIcon />
              </IconWrapper>
            </ListItemIcon>
            <ListItemText variant="h2"> {t('contracts:Vertrag')}</ListItemText>
          </ListItemStyled>
        </UserHas>
        <UserHas permission="stationcluster:create">
          <ListItemStyled disableGutters button onClick={handleClick(constants.stationClusterPannels.STATIONSCLUSTER)}>
            <ListItemIcon>
              <IconWrapper>
                <StationClusterIcon />
              </IconWrapper>
            </ListItemIcon>
            <ListItemText variant="h2"> {t('cluster:Stationscluster')}</ListItemText>
          </ListItemStyled>
        </UserHas>
      </List>
    </MenuWrapper>
  );
}

SelectAction.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default SelectAction;
