import { createContext, useCallback, useContext, useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import PageLoadingSpinner from '../components/PageLoadingSpinner';
import useErrorHandler from '../hooks/useErrorHandler';

import { updatePermissions } from './api';
import { useAuth0 } from './Auth0Provider';

export const PermissionsContext = createContext();
export const usePermissions = () => useContext(PermissionsContext);

export function PermissionsProvider({ children }) {
  const { initializeErrorHandler } = useErrorHandler();

  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    initializeErrorHandler();
  }, [initializeErrorHandler]);

  const [permissions, setPermissions] = useState([]);
  const [loadingPermissions, setLoadingPermissions] = useState(isAuthenticated);
  const componentMounted = useRef();

  const fetchPermissions = useCallback(async () => {
    if (isAuthenticated) {
      setLoadingPermissions(true);
      try {
        const permissionsResponse = await updatePermissions();
        if (componentMounted.current) {
          setPermissions(permissionsResponse.data);
          setLoadingPermissions(false);
        }
      } catch (error) {
        if (componentMounted.current) {
          setLoadingPermissions(false);
        }
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const hasPermissions = useCallback(
    (permission) => {
      if (!permission) {
        return true;
      }
      if (permissions === '*') {
        return true;
      }

      if (Array.isArray(permission)) {
        return permissions.some((r) => permission.indexOf(r) >= 0);
      }
      return permissions.indexOf(permission) >= 0;
    },
    [permissions]
  );

  const value = useMemo(
    () => ({
      loadingPermissions,
      permissions,
      hasPermissions,
    }),
    [loadingPermissions, permissions, hasPermissions]
  );

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  if (loadingPermissions) {
    return <PageLoadingSpinner />;
  }

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
}

PermissionsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
