import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import api, { apiResponseInterceptor } from '../utils/api';
import { useNotification } from '../utils/NotificationProvider';
import { setupAuth0Client } from '../utils/Auth0Provider';

const globalStatusCodes = [401, 403];

const useErrorHandler = () => {
  const { t } = useTranslation(['translation', 'permissions', 'notfound']);
  const { openAlert, openConfirmation } = useNotification();

  const errorHandler = useCallback(
    (error) => {
      if (!error.response || !globalStatusCodes.includes(error.response?.status)) {
        const message = error.response?.data?.message?.trim() || '';
        const errorMessage =
          message !== '' && message !== 'null'
            ? error.response?.data?.message
            : t('Beim Bearbeiten der Anfrage ist ein Fehler aufgetreten');
        openAlert(t('Fehler'), errorMessage);
      }
    },
    [openAlert, t]
  );

  const triggerLogout = useCallback(async () => {
    const auth0Client = await setupAuth0Client();
    return auth0Client.logout();
  }, []);

  const initializeErrorHandler = useCallback(() => {
    api.interceptors.response.use(apiResponseInterceptor, async (error) => {
      switch (error.response?.status) {
        case 401: {
          openConfirmation({
            content: t('session_expired_message'),
            disableBackdropClick: true,
            hideCancel: true,
            textConfirm: t('Logout'),
            title: t('Session abgelaufen'),
            handleRequest: triggerLogout,
          });
          break;
        }
        case 403: {
          openAlert(
            t('permissions:Keine Berechtigung'),
            t('permissions:Du hast nicht die erfordertliche Berechtigung diese Aktion durchzuführen')
          );
          break;
        }
        default:
          break;
      }
      return Promise.reject(error);
    });
  }, [openAlert, openConfirmation, t, triggerLogout]);

  return {
    errorHandler,
    initializeErrorHandler,
  };
};

export default useErrorHandler;
