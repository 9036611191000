import { createContext, useCallback, useContext, useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import SnackbarNotification from '../components/SnackbarNotification';
import AlertDialog from '../components/AlertDialog';
import ActionDialog from '../components/ActionDialog';

export const NotificationContext = createContext();
export const useNotification = () => useContext(NotificationContext);

const DURATION = 6000;

const NotificationWrapper = styled.span`
  display: inline;
`;

export function NotificationProvider({ children }) {
  const [snacks, setSnacks] = useState([]);
  const [alert, setAlert] = useState({ open: false });
  const [confirmationState, setConfirmationState] = useState({});
  const [loading, setLoading] = useState(false);

  const componentUnmounted = useRef();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const awaitingPromiseRef = useRef();

  useEffect(() => {
    componentUnmounted.current = true;
    return () => {
      componentUnmounted.current = false;
    };
  }, []);

  const openSnackbar = useCallback((content, { type, title } = {}) => {
    const key = new Date().getTime() + Math.random();
    const snack = {
      key,
      message: content,
      open: true,
      type,
      title,
    };
    setSnacks((existingSnacks) => [...existingSnacks, snack]);
  }, []);

  const closeSnackbar = useCallback((key) => {
    setSnacks((existingSnacks) => existingSnacks.filter((item) => item.key !== key));
  }, []);

  const handleOnCloseSnackbar = (event, reason, key) => {
    if (reason === 'clickaway') {
      return;
    }
    closeSnackbar(key);
  };

  const openAlert = useCallback((title, message) => {
    setAlert({
      title,
      message,
      open: true,
    });
  }, []);

  const closeAlert = useCallback(() => {
    setAlert({
      open: false,
    });
  }, []);

  const handleOnCloseAlert = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      closeAlert();
    },
    [closeAlert]
  );

  const openConfirmation = useCallback((options) => {
    setConfirmationState(options);

    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  const closeConfirmation = () => {
    if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    if (componentUnmounted.current) {
      setConfirmationState({});
    }
  };

  const submitConfirmation = async () => {
    setLoading(true);
    try {
      if (confirmationState.handleRequest) {
        await confirmationState.handleRequest();
      }
      if (awaitingPromiseRef.current) {
        awaitingPromiseRef.current.resolve();
      }
    } catch (error) {
      awaitingPromiseRef.current.reject(error);
    }
    if (componentUnmounted.current) {
      setLoading(false);
      setConfirmationState({});
    }
  };

  const value = useMemo(
    () => ({
      openSnackbar,
      closeSnackbar,
      openAlert,
      closeAlert,
      openConfirmation,
    }),
    [openSnackbar, closeSnackbar, openAlert, closeAlert, openConfirmation]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <NotificationWrapper>
        {snacks.length > 0 &&
          snacks.map((snack) => (
            <SnackbarNotification
              key={snack.key}
              id={snack.key}
              message={snack.message}
              duration={DURATION}
              open={snack.open}
              type={snack.type}
              title={snack.title}
              onClose={(event, reason) => {
                handleOnCloseSnackbar(event, reason, snack.key);
              }}
            />
          ))}
        {alert.open && (
          <AlertDialog
            fullScreen={fullScreen}
            message={alert.message}
            title={alert.title}
            open={alert.open}
            onClose={(event, reason) => {
              handleOnCloseAlert(event, reason);
            }}
          />
        )}
        {Object.keys(confirmationState).length > 0 && (
          <ActionDialog
            title={confirmationState.title}
            content={confirmationState.content}
            confirmationButtonText={confirmationState.textConfirm}
            disableBackdropClick={confirmationState.disableBackdropClick || false}
            hideCancel={confirmationState.hideCancel || false}
            isLoading={loading}
            open={confirmationState !== null}
            handleConfirmation={submitConfirmation}
            handleClose={closeConfirmation}
          />
        )}
      </NotificationWrapper>
    </NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
